import React,{ useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';

const Features = () => {
   useEffect(()=>{
      AOS.init({duration:2000})
    },[]);
  return (
    <>
        <div className='container-fluid subcolor overflow-hidden'>
           <div className='container mx-auto  py-24 '>
              <div className='mt-10 relative px-4' >
              <h1 className="mx-0 md:text-center font-bold   text-3xl sm:text-4xl  xl:text-[40px]	 text-white   leading-[45px] sm:leading-[56px] lg:leading-[58px]" ><span className='bg-gradient-to-r from-[#454545] from-30% via-[rgba(69, 69, 69, 0.5)] via-25% to-transparent to-90% rounded-2xl pl-3 py-1'> Main  </span> Features of Sheba Music</h1>
             {/* <img src='./Images/Group 1000002614.png '   alt='img'  className='absolute  right-1 top-[-100px]   sm:right-1 sm:top-[-80px] md:right-1 md:top-[-30px] lg:right-28 xl:right-52 xl:top-[-15px]'/> */}
              </div>
              <div className='py-6 mx-0 px-5 md:px-0  md:mx-auto w-[95%] sm:w-4/5 md:w-[70%] lg:w-2/4'>
                <p className='mx-0 md:text-center text-white  text-lg	 font-normal'>Experience a revolutionary music journey with Sheba Music's Innovative Features that are given below.</p>
              </div>
              <div className='mx-auto  '>
              <div className='grid grid-cols-1  sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-7 py-5 mx-0 md:mx-4 xl:mx-0'>
              <div className='secsubcolors rounded-[40px] p-3 mx-3 sm:mx-0' style={{boxShadow: "9px 9px 18px 0px #00000040, -9px -9px 18px 0px #FFFFFF15"}}>
              <div class="flex items-center justify-center">
               <div className='flex items-center justify-center w-[100px] h-[100px] bg-[#A30000] my-7  rounded-[10px] inset-0 shadow-md	hover:bg-[#24272C]' style={{boxShadow: "6px 6px 12px 0px #00000040, -6px -6px 12px 0px #FFFFFF15"}}>
                  <img src='./Images/Group 1000002362.png' alt='img'/>
               </div>
              </div>
                 <div className='text-center text-white text-xl	 font-bold	leading-10		'>
                 Music Marketplace
                 </div>
                 <div className='text-center text-[#DADADA] font-normal px-4 leading-6	py-3  text-base	'>
                 Sheba Music empowers artists to sell, fans to explore, and creates community for diverse musical experiences.
                 </div>
              </div>
              <div className='secsubcolors     rounded-[40px] p-3  mx-3 sm:mx-0' style={{boxShadow: "9px 9px 18px 0px #00000040, -9px -9px 18px 0px #FFFFFF15"}}>
              <div class="flex items-center justify-center">
               <div className='flex items-center justify-center w-[100px] h-[100px] bg-[#A30000] my-7  rounded-[10px] inset-0 shadow-md	hover:bg-[#24272C]' style={{boxShadow: "6px 6px 12px 0px #00000040, -6px -6px 12px 0px #FFFFFF15"}}>
                  <img src='./Images/7935ec95c421cee6d86eb22ecd1368a9.png' alt='img'/>
               </div>
              </div>
                 <div className='text-center text-white text-xl font-bold	leading-10		'>
                 YouTube Promotion
                 </div>
                 <div className='text-center text-[#DADADA] font-normal px-4 leading-6	py-3 text-base		'>
                 Artists can promote their YouTube videos to millions of fans through the Sheba Music platform.                 </div>
              </div>
              <div className='secsubcolors    rounded-[40px] p-3  mx-3 sm:mx-0' style={{boxShadow: "9px 9px 18px 0px #00000040, -9px -9px 18px 0px #FFFFFF15"}}>
              <div class="flex items-center justify-center">
               <div className='flex items-center justify-center w-[100px] h-[100px] bg-[#A30000] my-7  rounded-[10px] inset-0 shadow-md	hover:bg-[#24272C]' style={{boxShadow: "6px 6px 12px 0px #00000040, -6px -6px 12px 0px #FFFFFF15"}}>
                  <img src='./Images/Groupmsg.png' alt='img'/>
               </div>
              </div>
                 <div className='text-center text-white text-xl font-bold	leading-10		'>
                 1-1 Video Platform
                 </div>
                 <div className='text-center text-[#DADADA] font-normal px-4 leading-6	py-4 text-base	'>
                 Engage in direct, one-on-one video interactions with your favorite artists for a more personal experience.                 </div>
              </div>
              <div className='secsubcolors     rounded-[40px] p-3 mx-3 sm:mx-0' style={{boxShadow: "9px 9px 18px 0px #00000040, -9px -9px 18px 0px #FFFFFF15"}}>
              <div class="flex items-center justify-center">
               <div className='flex items-center justify-center w-[100px] h-[100px] bg-[#A30000] my-7  rounded-[10px] inset-0 shadow-md	hover:bg-[#24272C]' style={{boxShadow: "6px 6px 12px 0px #00000040, -6px -6px 12px 0px #FFFFFF15"}}>
                  <img src='./Images/Group 1000002608.png' alt='img'/>
               </div>
              </div>
                 <div className='text-center text-white text-xl font-bold	leading-10		'>
                 Transparent Analytics
                 </div>
                 <div className='text-center text-[#DADADA] font-normal px-4 leading-6	py-3 text-base	'>
                 Access transparent analysis on downloads, sales, fan monetary support, and streaming metrics for a comprehensive overview.                 </div>
              </div>
              <div className='secsubcolors    rounded-[40px] p-3 mx-3 sm:mx-0'style={{boxShadow: "9px 9px 18px 0px #00000040, -9px -9px 18px 0px #FFFFFF15"}}>
              <div class="flex items-center justify-center">
               <div className='flex items-center justify-center w-[100px] h-[100px] bg-[#A30000] my-7  rounded-[10px] inset-0 shadow-md	hover:bg-[#24272C]' style={{boxShadow: "6px 6px 12px 0px #00000040, -6px -6px 12px 0px #FFFFFF15"}}>
                  <img src='./Images/Group 2.png' alt='img'/>
               </div>
              </div>
                 <div className='text-center text-white text-xl font-bold	leading-10		'>
                 Offline Playback
                 </div>
                 <div className='text-center text-[#DADADA] font-normal px-4 leading-6	py-3 text-base	'>
                 Play downloaded songs offline, ensuring uninterrupted music enjoyment even without an internet connection.                 </div>
              </div>
              <div className='secsubcolors     rounded-[40px] p-3 mx-3 sm:mx-0' style={{boxShadow: "9px 9px 18px 0px #00000040, -9px -9px 18px 0px #FFFFFF15"}}>
              <div class="flex items-center justify-center">
               <div className='flex items-center justify-center w-[100px] h-[100px] bg-[#A30000] my-7  rounded-[10px] inset-0 shadow-md	hover:bg-[#24272C]' style={{boxShadow: "6px 6px 12px 0px #00000040, -6px -6px 12px 0px #FFFFFF15"}}>
                  <img src='./Images/Ticket.png' alt='img'/>
               </div>
              </div>
                 <div className='text-center text-white text-xl font-bold	leading-10		'>
                 Ticketing Platform                
                  </div>
                 <div className='text-center text-[#DADADA] font-normal px-4 leading-6	py-3 text-base	'>
                 Seamlessly purchase event tickets through Sheba Music for live shows and experiences.                       </div>
              </div>
              <div className='secsubcolors     rounded-[40px] p-3 mx-3 sm:mx-0' style={{boxShadow: "9px 9px 18px 0px #00000040, -9px -9px 18px 0px #FFFFFF15"}}>
              <div class="flex items-center justify-center">
               <div className='flex items-center justify-center w-[100px] h-[100px] bg-[#A30000] my-7  rounded-[10px] inset-0 shadow-md hover:bg-[#24272C]	' style={{boxShadow: "6px 6px 12px 0px #00000040, -6px -6px 12px 0px #FFFFFF15"}}>
                  <img src='./Images/Group 1000002612.png' alt='img'/>
               </div>
              </div>
                 <div className='text-center text-white text-xl font-bold	leading-10		'>
                 Showcase Talent
                 </div>
                 <div className='text-center text-[#DADADA] font-normal px-4 leading-6	py-3 text-base	'>
                 Artists can display their skills by offering free songs and beats for fans to stream.              
                  </div>
              </div>
              <div className='secsubcolors rounded-[40px] p-3 mx-3 sm:mx-0' style={{boxShadow: "9px 9px 18px 0px #00000040, -9px -9px 18px 0px #FFFFFF15"}}>
              <div class="flex items-center justify-center">
               <div className='flex items-center justify-center w-[100px] h-[100px] bg-[#A30000] my-7  rounded-[10px] inset-0 shadow-md hover:bg-[#24272C]' style={{boxShadow: "6px 6px 12px 0px #00000040, -6px -6px 12px 0px #FFFFFF15"}}>
                  <img src='./Images/Group 1000002607.png' alt='img'/>
               </div>
              </div>
                 <div className='text-center text-white text-xl font-bold	leading-10		'>
                 Messaging Platform
                 </div>
                 <div className='text-center text-[#DADADA] font-normal px-4 leading-6	py-3 text-base	'>
                 Connect directly with your favorite artists and producers through Sheba Music's messaging feature.           
                  </div>
              </div>


              </div>
              </div>
             
           </div>
        </div>
    </>
  )
}
export default Features
