import React,{ useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css'; 

const Newsletter = () => {
   useEffect(()=>{
      AOS.init({duration:2000})
    },[]);
   const boxesShadow={
      boxShadow:' 6px 6px 12px 0px rgba(0, 0, 0, 0.2),  -5px -5px 10px 0px rgba(255, 255, 255, 0.1)',
      
    }
  return (
     <>
        <div className='container-fluid   mb-16 overflow-hidden	 '>
        <div className='w-full h-[50px]  	 '>
        {/* <img src='Images/Group 1000002606.png' alt='img' className='absolute w-[100px] right-0'/> */}
        {/* <img src='Images/Group twoplus.png' alt='img' className=' absolute right-56	'/>       */}
           </div>
        <div className='container  mx-auto  '  >
        <div className='grid grid-cols-1  sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-4  px-4 mt-0 sm:mt-14 md:mt-10 lg:mt-5 '>
        <div className='mt-24 sm:mt-10 xl:mt-10 '>
              <img src='./Images/Group 1000005940.png' alt='img' className='w-[150px]   xl:w-[196px]  py-0 xl:py-8 ml-2 '/>
            </div>
            <div className='mt-0 xl:mt-10  '>
            <div className='mx-0 sm:mx-1  lg:mx-14'>
            <h1 className='text-white font-bold text-2xl		pt-8 	'>Our Latest <span className='text-[#A30000]'>Newsletter</span> </h1>
            </div>
            <div className='flex flex-col sm:flex-row md:flex-row lg:flex-row mx-0 sm:mx-0 md:mx-0  lg:mx-14'>
             <div className=' my-4'>
               <input type='text' placeholder='Enter your Name address' className='w-full sm:[200px] md:w-[230px] lg:w-[300px] py-3 m rounded-[10px] text-sm	font-light	 outline-none	 px-3 ' />
            
             </div>
             <div  className=' my-1 sm:my-4 md:my-4 lg:my-4'> 
                 <button className='w-full md:w-[100px] lg:w-[121px] py-3 lg:py-3  bg-[#A30000] mx-0 sm:mx-1 md:mx-2 lg:mx-3 text-white rounded-[10px] text-sm hover:bg-[#454545] transition-all duration-800 ease-in-out' style={boxesShadow} >Subscribe</button>
                </div>

            </div>
            </div>
        </div>
       

        </div>
        <div className="mx-auto flex max-w-7xl items-center justify-between sm:justify-center p-6 lg:px-8 ">
        <a href="/privacy-policy" className="text-sm  font-bold leading-6 text-white sm:px-8">
            Privacy Policy
          </a>
          <a href="/terms-of-service" className="text-sm  font-bold leading-6 text-white sm:px-8">
            Terms of Service
          </a>
          <a href="/terms-conditions" className="text-sm  font-bold leading-6 text-white sm:px-8">
            Terms and Conditions
          </a>
          <a href="/dmca" className="text-sm  font-bold leading-6 text-white sm:px-8">
            DMCA
          </a>

        </div>
        </div>
     </>
  )
}

export default Newsletter