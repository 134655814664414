import React from 'react'
const Navbar = () => {
  const boxesShadow={
    boxShadow:' 6px 6px 12px 0px rgba(0, 0, 0, 0.2),  -5px -5px 10px 0px rgba(255, 255, 255, 0.1)',
    
  }

  return (
    <>
        <div className='container-fluid  subcolor'>
          <div className='container mx-auto  flex justify-between  px-4 py-10'>
          <div >
          <a href="/">
          <img src='./Images/Group 1000002551.png' alt='this one ' className='w-[120px]'/>
          </a>
           
            </div>
             <div>
             <button className='bg-gradient-to-b from-[#454545]  to-[rgba(69,69,69,0.5)] px-6 py-3 
              text-white rounded-2xl font-bold text-sm hover:bg-[#24272C] transition-all duration-800 ease-in-out	' style={boxesShadow}>Contact Us</button>
             </div>
          </div>
        </div>
    </>
  )
}
export default Navbar
