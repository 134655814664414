import React, { useEffect, useState } from 'react'
import Navbar  from './../Navbar'

// import Newlatter from './newlatter'
import Newsletter from './../newsletter'
import { useLocation } from 'react-router-dom'
import { isAndroid, isIOS } from 'react-device-detect'
const TermOfService = () => {
 
  return (
    <div >  
    <Navbar/>
   <div  className=" px-8 md:px-12 _Text_1x6i2_1">
 
    <div className=" pr-10 pt-50 pt-xs-30 pt-md-30 pb-15">
   <div className="about_headerSection__bgNR5">
   <h1 className="py-10 sm:py-28 text-xl sm:text-4xl font-bold text-center text-white">
   Sheba Music Terms Of Service
   </h1>
   </div>
   <div className="" role="region">
   <div className="about_pageSection__vYP_F">
    
   <h2 className='_Text_Heading'>
   User Acceptance
   </h2>
   <p className="_Text_1x6i2_1">
   This Sheba Music LLC. (“Sheba Music”) Terms of Service, in conjunction with the <a className='text-red-600' href="/privacy-policy"> Privacy Policy</a> and other terms and conditions of use which are incorporated herein by reference and may be posted and applicable to specific services, governs your use of the Sheba Music LLC. website (“shebamusicapp.com”) and any Sheba Music platform (“Platform”) specifically including but not limited to the Sheba Music mobile application, is a LEGALLY BINDING CONTRACT, and are referred to throughout as the “Agreement’.
   </p>
   <p className="_Text_1x6i2_1">
   By visiting the shebamusicapp.com, using any content, products or services provided to you on, from, or through the Sheba Music LLC. website or Platform (collectively the “Services”) you are signifying that you have read the Agreement, that you understand the Agreement, and you agree to be bound by all of the terms of the Agreement.</p>
   <p className="_Text_1x6i2_1">
   If you don’t completely agree with the terms of the agreement, but still use any Sheba Music Service or Platform, you will still be bound to the terms of the agreement.</p>
   <p className="_Text_1x6i2_1">
   Sheba Music may modify this Agreement at any time without any prior notice to you and said modifications may become effective immediately upon their posting to the website. Your continued use of any Sheba Music Service or Platform following a modification to this Agreement indicates your acceptance of the Agreement and you agree to be bound by any such modification. If you are dissatisfied with anything related to any shebamusicapp.com Service or Platform your sole remedy is to discontinue use of said Service or Platform. You use any Sheba Music Service or Platform at your own risk.</p>
   <h2 className='_Text_Heading'>Services - Terms of Use</h2>
   <p className="_Text_1x6i2_1">
   The Agreement applies to all users of any Sheba Music Service or Platform, including users who contribute content. “content” includes text, software, scripts, graphics, photos, sounds, music, videos, audiovisual combinations, interactive features and other materials you may contribute to any Sheba Music Service or Platform. This includes all aspects of Sheba Music including but not limited to, all products, software and services offered via the Sheba Music website and mobile app.</p>
   <p className="_Text_1x6i2_1">Sheba Music may change, suspend or discontinue all or any aspect of any Service or Platform at any time, including the availability of any feature, database, or content, without prior notice or liability.</p>
   <p className="_Text_1x6i2_1">Sheba Music may contain links to third party websites. By use of any Sheba Music Service or Platform, you expressly relieve Sheba Music LLC. from any and all liability arising from your use of any third-party website.</p>
   <p className="_Text_1x6i2_1">By signing up for an Sheba Music account you opt-in to receiving emails from Sheba Music. At times Sheba Music will send account holders emails notifying them of system upgrades, issues and other messages. You will have the option to opt-out of these emails by clicking the unsubscribe link included in any of our email messages.</p>
   
   <h2 className='_Text_Heading'>Services - Terms of Use for Auto-Renewing Subscriptions</h2>
   <p className="_Text_1x6i2_1">By subscribing to the Sheba Music app, you agree to the following terms and conditions::</p>
   <p className="_Text_1x6i2_1"><strong>(1)</strong> Subscription Options: Sheba Music offers two types of auto-renewing subscriptions: Basic and Premium.</p>
   <ul style={{ listStyleType: 'disc' }} className='px-7'>
   <li>Basic Subscription: Providing access to standard features and content for a period of 3 months. The cost of the Basic Subscription is 14.99(USD)</li>
   <li>Premium Subscription: Providing access to all features and content, including premium content and exclusive features, for a period of 3 months. The cost of the Premium Subscription is $19.99 (USD)</li>
   </ul>
   <p className="_Text_1x6i2_1"><strong>(2)</strong> Auto-Renewal: Your subscription will automatically renew at the end of the subscription period unless you choose to cancel at least 24 hours before the renewal date. You will be charged for the subsequent subscription period within 24 hours prior to the end of the current subscription period.</p>
   <p className="_Text_1x6i2_1"><strong>(3)</strong> Payment: Payment will be charged to your Google Play or iTunes Account at confirmation of purchase. Your Google Play or iTunes Account will be charged for renewal within 24-hours prior to the end of the current period, at the same price as the current subscription period.</p>
   <p className="_Text_1x6i2_1"><strong>(4)</strong> Cancellation: You may cancel your subscription at any time by going to your Account Settings after purchase. Cancellation will take effect at the end of the current subscription period.</p>
   <p className="_Text_1x6i2_1"><strong>(5)</strong> Refunds: No refunds will be provided for any unused portion of the subscription period.</p>
   <p className="_Text_1x6i2_1"><strong>(6)</strong> Changes to Subscription: Sheba Music reserves the right to modify the subscription options and pricing at any time. Any changes to the subscription will be communicated to you in advance.</p>
   <p className="_Text_1x6i2_1"><strong>(7)</strong> Data Usage: Your use of the Sheba Music app may consume data, and you are responsible for any data charges incurred.</p>
   <p className="_Text_1x6i2_1"><strong>(8)</strong> Data Usage: Your use of the Sheba Music app may consume data, and you are responsible for any data charges incurred.</p>
   <p className="_Text_1x6i2_1"><strong>(8)</strong> Platform-Specific Terms:</p>
   <ul style={{ listStyleType: 'disc' }} className='px-7'>
   <li>Google Play Store: Your use of the app is also subject to the Google Play Store Terms of Service.</li>
   <li>iOS App Store: Your use of the app is also subject to the App Store Terms of Service.</li>
   </ul>
   <p className="_Text_1x6i2_1">By subscribing to Sheba Music, you acknowledge that you have read, understood, and agreed to these Terms of Use.</p>

   <h2 className='_Text_Heading'>Acceptable Use of the Service – User Responsibility</h2>
   <p className="_Text_1x6i2_1">You agree while using any Sheba Music Service or Platform that you MAY NOT:</p>
   <ul style={{ listStyleType: 'disc' }} className='px-7'>
   <li>Distribute an illegal or unauthorized copy of another person’s trademarked or copyrighted work;</li>
   <li>Alter or modify any part of the Services;</li>
   <li>Alter or modify any part of the Platform;</li>
   <li>Use the Services for any illegal purpose;</li>
   <li>Use the Platform for any illegal purpose;</li>
   <li>Use any spider, readers, site search application, or other device to retrieve or index any portion of the Service or Platform, with the exception of major public search engines;</li>
   <li>Transmit any viruses, worms, spam or malware or other items of a destructive nature;</li>
   <li>Upload any content that includes code hidden or otherwise contained within the content which is unrelated to the content;</li>
   <li>Reformat or frame any portion of any web page that is part of the Service or Platform;</li>
   <li>Collect or harvest any personal identifiable information or account names or solicit users;</li>
   <li>Impersonate another person or artist;</li>
   <li>Violate or attempt to violate Sheba Music systems or interfere with the normal use of the Service or Platform by users;</li>
   <li>Post advertisements, promotions or solicitations;</li>
   <li>Submit any content that falsely implies sponsorship of the content by the Service or Platform, falsify or delete any author attribution in any content, or promote any information that you know is false or misleading;</li>
   <li>Post any links to any external Internet sites that are obscene or sexually explicit material of any kind;</li>
   <li>Sell or transfer or offer to sell or transfer an Sheba Music account to a third party without prior written approval from Sheba Music;</li>
   <li>Artificially increase play counts or other statistics through the use of bots, pay-for-play services or other means</li>
   </ul>
   <h2 className='_Text_Heading'>Sheba Music Accounts</h2>
   <p className="_Text_1x6i2_1">To access or benefit from some of the Sheba Music Service or Platform you will have to create an Sheba Music account. You are responsible for maintaining the confidentiality of your access information and are responsible for all activities that occur utilizing your information. Although Sheba Music will not be liable for any losses you might suffer, you may be liable for the losses of Sheba Music or others.</p>
   <h2 className='_Text_Heading'>User Content and Conduct</h2>
   <p className="_Text_1x6i2_1">In addition to the general restrictions above, the following restrictions and conditions apply specifically to your use of the content. You will comply with laws regarding transmission of data.</p>
   <p className="_Text_1x6i2_1">Content is provided to you as is. You understand that when using any Sheba Music Service or Platform, you will be exposed to content from a many different sources, and that Sheba Music is not responsible for the accuracy, usefulness, safety, or intellectual property rights of such content. You further understand and acknowledge that you may be exposed to content that is inaccurate, offensive, indecent, or objectionable, and you agree to waive, and hereby do waive, any legal or equitable rights or remedies you have or may have against Sheba Music with respect to the extent permitted by USA law, agree to indemnify and hold harmless Sheba Music its owners, operators, affiliates, licensors, and licensees to the fullest extent allowed by law regarding all matters related to your use of the Service or Platform. You acknowledge your use of content is at your sole risk.</p>
   <p className="_Text_1x6i2_1">You shall be solely responsible for your own content and the consequences of storing and distributing your content on the Sheba Music Service or on any Sheba Music Platform. You affirm, represent, and warrant that you own or have the necessary licenses, rights, consents, and permissions to distribute content through the Sheba Music Service or on any Sheba Music Platform; and you license to Sheba Music all patent, trademark, copyright or other proprietary rights for distribution on any Sheba Music Service or Platform pursuant to the Agreement.</p>
   <p className="_Text_1x6i2_1">For clarity, you retain all of your ownership rights in your content. However, by distributing content through shebamusicapp.com, you grant Sheba Music a non-exclusive, royalty-free license to use, reproduce, distribute, display, perform uploaded content of any kind (including but not limited to audio files, cover art, and metadata) in connection with any Sheba Music Service, Platform, promotional materials (including but not limited to marketing communications and app store listings) Sheba Music’s (including but not limited to its successors and affiliates) business. By accepting these terms and uploading your content, you agree and understand that these materials will be made available to iOS and Android app users who select to store your material on their device for the purpose of offline play. As a reminder - you cannot upload any third party content which infringes on third party intellectual property or copyrights, and you cannot make any material available that you are not the authorized copyright holder for. You will not use Sheba Music to upload or distribute any content that is abusive, libelous, defamatory, pornographic or obscene, that promotes or incites violence, terrorism, illegal acts, or hatred, or is otherwise objectionable in Sheba Music’s reasonable discretion.</p>
   <p className="_Text_1x6i2_1">Sheba Music will respond to any and all takedown requests that comply with the requirements of the Digital Millennium Copyright Act (“DMCA”), and other applicable intellectual property laws. To file a copyright infringement notification with us you will need to send a written or electronic communication to Sheba Music at the email address <a href="mailto:support@shebamusicapp.com">support@shebamusicapp.com</a> or via our <a href="https://shebamusicapp.com/about/legal">webform</a> which includes the following information:</p>
   <p className="_Text_1x6i2_1"><strong>(1)</strong> A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.</p>
   <p className="_Text_1x6i2_1"><strong>(2)</strong> Identification of the copyrighted work claimed to have been infringed. Please include a screenshot of the alleged copyright infringement on the Sheba Music platform.</p>
   <p className="_Text_1x6i2_1"><strong>(3)</strong> Information reasonably sufficient to permit Sheba Music to contact the complaining party, such as an address, telephone number and, if available, an electronic mail address at which the complaining party may be contacted.</p>
   <p className="_Text_1x6i2_1">You understand and agree that Sheba Music reserves the right to delete, move, or edit any content that it may determine violates the Agreement and/or Privacy Policy or is otherwise unacceptable and may terminate your access to the Services, without prior notice and at its sole discretion.</p>
   <p className="_Text_1x6i2_1">You control your data through your account and/or cookies which may be placed on your computer. Sheba Music maintains multiple copies of active files. Sheba Music bears no responsibility for maintaining your data.</p>
   <p className="_Text_1x6i2_1">shebamusicapp.com is not a data warehousing website. The intended use is for active file sharing which may inadvertently result in backups of files being shared.</p>
   <p className="_Text_1x6i2_1">Any views expressed on the website do not reflect the views of Sheba Music.</p>
   <h2 className='_Text_Heading'>Warranty Disclaimer and Limitation of Liability</h2>
   <p className="_Text_1x6i2_1">YOU AGREE THAT YOUR USE OF THE SERVICES SHALL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, Sheba Music, ITS OFFICERS, EMPLOYEES, AND AGENTS DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR USE THEREOF.</p>
   <p className="_Text_1x6i2_1">NEITHER Sheba Music NOR ANY PROVIDER OF ANY THIRD PARTY CONTENT WARRANTS THAT THE SERVICES WILL BE UNINTERRUPTED OR ERROR FREE OR MAKES ANY WARRANTY OF THE RESULTS TO BE OBTAINED FROM USE OF THE SERVICES OR CONTENT.</p>
   <p className="_Text_1x6i2_1">NEITHER Sheba Music NOR ANY THIRD PARTY WARRANT OR GUARANTEE THAT ANY FILES AVAILABLE FOR DOWNLOADING THROUGH ANY Sheba Music SERVICE OR PLATFORM WILL BE FREE OF VIRUSES OR SIMILAR DESTRUCTIVE FEATURES. YOU AGREE THAT YOU ASSUME ALL RISK AS TO THE QUALITY AND PERFORMANCE OF THE SERVICES AND THE ACCURACY OF THE CONTENT. Sheba Music IS NOT RESPONSIBLE UNAUTHORIZED OR ALTERATION OF YOUR CONTENT OR FOR ANY VIOLATION OF ITS AGREEMENT.</p>
   <p className="_Text_1x6i2_1">Sheba Music DOES NOT WARRANT, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SERVICES OR ANY HYPERLINKED SERVICES OR ADVERTISING, AND Sheba Music WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.</p>
   <p className="_Text_1x6i2_1">NEITHER Sheba Music, NOR ANY THIRD PARTY PROVIDER SHALL BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES ARISING OUT OF THE USE OF OR INABILITY TO USE THE SERVICES, EVEN IF SUCH PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT WILL Sheba Music’s AGGREGATE LIABILITY TO YOUR OR ANY THIRD PARTY FOR ALL CLAIMS ARISING OUT OF THE USE OF THE SERVICES. THE LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE DEAL BETWEEN Sheba Music AND YOU. IN STATES OR COUNTRIES NOT ALLOWING EXCLUSION OF WARRANTIES OR LIMITATION OF LIABILITY FOR INCIDENTAL DAMAGES, Sheba Music AND ANY THIRD PARTY PROVIDER SHALL BE LIMITED TO THE GREATEST EXTENT OF THE LAW.</p>
   <h2 className='_Text_Heading'>Indemnification</h2>
   <p className="_Text_1x6i2_1">You agree to indemnify, defend and hold Sheba Music and its affiliates and their respective officers, owners, employees, agents, information providers and licensors harmless from and against any and all claims, liability, losses, damages, obligations, costs and expenses, including attorney’s fees, incurred by any Sheba Music Party in connection with any content or use of any Sheba Music Service or Platform, whether via your password and by any other person, whether or not authorized by you. This defense and indemnification obligation will survive these Terms of Service and your use of the Services.</p>
   <h2 className='_Text_Heading'>General Terms and Conditions</h2>
   <p className="_Text_1x6i2_1">The Agreement shall be construed in accordance with the laws of the State of New York, without reference to principles of choice of law. You and Sheba Music each irrevocably consent to the personal jurisdiction of the federal or state courts located in New York County, New York (“Courts”) with respect to any action, suit or proceeding arising out of or related to the Agreement or to the Services and /or content and waive any objection to venue in any of the Courts for such as action, suit or proceeding; additionally, you agree that you will not bring any such action, suit or proceeding in any court other than the Courts.</p>
   <p className="_Text_1x6i2_1">These Terms of Service, together with the Privacy Policy and any other legal notices published by Sheba Music on any Sheba Music Service, Platform or within the Agreement, constitutes the entire agreement between the parties with respect to the subject matter hereof, and supersedes all previous written or oral agreements between the parties with respect to such subject matter. If any inconsistency exists between the terms of the Agreement and any additional terms and conditions posted on any Sheba Music Service or Platform, such terms shall be interpreted as to eliminate any inconsistency, if possible, and otherwise, the additional terms and conditions shall control.</p>
   <p className="_Text_1x6i2_1">Please e-mail reports of any violations to <a className='text-red-500' href="mailto:support@shebamusicapp.com">support@shebamusicapp.com</a></p>
   <h2 className='_Text_Heading'>Sheba Music+ Premium Subscription Refund Policy</h2>
   <p className="_Text_1x6i2_1">If your Sheba Music subscription was purchased via the Sheba Music website, you can cancel your subscription at any time before the end of your 30-day payment period. You will be able to maintain your premium subscription until the following payment cycle, at which point your account will return to the free tier. We do not provide refunds or credits for any partial subscription periods.</p>
   <p className="_Text_1x6i2_1">You can cancel this subscription by selecting “manage subscriptions” within your Sheba Music profile on the Sheba Music app or website.</p>
   <p className="_Text_1x6i2_1">If you believe you were charged too much, or charged multiple times, please reach out to support via our <a className='text-red-500' href="mailto:support@shebamusicapp.com" >(Submit a request – Sheba Music Help (support@shebamusicapp.com))</a> . This likely means you are paying for multiple accounts, and we will help resolve the situation.</p>
   <p className="_Text_1x6i2_1">Sheba Music reserves the right to increase the price of subscriptions via advanced notice to the user. Sheba Music may increase the price of subscriptions due to a variety of reasons, including, but not limited to; changing market conditions, competitor pricing, and licensing agreements with content partners.</p>
   <p className="_Text_1x6i2_1">If you have a Subscription and your account is suspended or terminated by Sheba Music due to infringing upon Sheba Music’s terms of service, you will not be entitled to any refund for any unexpired period of your subscription. If your account is terminated because Sheba Music elects to cease providing access to the platform in the jurisdiction where you reside or from where you are attempting to access the platform, or in other reasonable circumstances as determined by Sheba Music at its discretion, refunds may be payable at the reasonable discretion of Sheba Music. Sheba Music has no liability to you, nor any obligation to provide a refund to you, in connection with internet or other service outages or failures that are caused by the actions of government authorities, other third parties, or events beyond our control.</p>
   <div className="about_pageSection__vYP_F">
   <h2 className='_Text_Heading'>Sheba Music Supporters Program – Listener Terms</h2>
   <p className="_Text_1x6i2_1">Sheba Music’s Supporters program (the “Program”) enables you to support Artists through the purchase of digital tokens (“Tokens”), which you can use to show your appreciation for a particular track or album (“Project”) on the Sheba Music Service or Platform (as defined in the Sheba Music Terms of Service). Tokens include a badge that you can include on your Sheba Music profile, and/or an image of the Project artwork that you can share via your other social media profiles and channels, in each case demonstrating your support for the relevant Project. Sheba Music intends to share revenue from purchase of Tokens with applicable Artists.</p>
   <p className="_Text_1x6i2_1">If you choose to purchase or use Tokens, these Terms apply to you. These Terms may change from time to time. We will notify you of any significant changes by email, but we also recommend you review this page periodically to make sure you’re aware of the latest Terms.</p>
   <ul style={{ listStyleType: 'disc' }} className='px-7'>
   <li>The Program is only open to certain Artists on the Sheba Music Service and Platform, as determined by us at our discretion. For this reason, you may not always be able to buy Tokens for every Artist or Project that you may wish to support.</li>
   <li>You can purchase Tokens if you are at least 18 years of age (or the age of majority in your jurisdiction), or if you are at least 13 years of age and are acting under the supervision of a parent or legal guardian who agrees to be bound by these Terms.</li>
   <li>Using Tokens to support an Artist’s Project does not give you any rights with respect to that Project.</li>
   <li>Sheba Music intends to share the revenue generated from the sale of Tokens with applicable Artists and/or their representatives under the terms of agreements that Sheba Music has entered into with those Artists and/or their representatives. Sheba Music may retain a share of this revenue for itself, and may also be required to share revenue with certain third-party platform providers under the terms of agreements with those third-party platform providers. For this reason, Sheba Music cannot guarantee that the money you spend on Tokens will be shared exclusively with the specific Artists whose Projects you choose to support using your Tokens.</li>
   <li>We may change or withdraw the Program at any time at our sole discretion, and subject to applicable law, we will not be obligated to refund the value of any Tokens, or otherwise be liable to you in any way, if we do so. Please bear this in mind when deciding whether to purchase Tokens, or how many Tokens you wish to purchase at any given time. We may also introduce or discontinue different types of Token or change the price of Tokens at any time, although this will not affect any Tokens you have already purchased.</li>
   <li>The price of Tokens will be provided at the time of purchase. All charges and payments for Tokens will be made in the currency specified at the time of purchase. Currency exchange settlements, foreign transaction fees and payment channel fees, if any, are based on your agreement with the applicable payment provider.</li>
   <li>All sales of Tokens are final. Tokens are non-refundable, except as required by applicable law. Tokens are available to be used as soon as your payment is processed, and by purchasing Tokens, you agree that you will waive any statutory right you may have to change your mind about your purchase, or to cancel your purchase within any applicable cooling off period.</li>
   <li>The associated value of the Tokens that you purchase will be credited to your Sheba Music account. If you choose to support a Project using your Token(s), the associated value of the Token(s) will be deducted from your Sheba Music account. This deduction is final, and you will not be entitled to reverse or cancel your support, or claim any refund or credit, for example, if you choose to delete any post featuring your Token on any third party platform.</li>
   <li>If any Tokens are subject to sales tax in your jurisdiction and you have not paid the applicable sales tax to us when you purchase your Tokens, then you will be responsible for the payment of the applicable sales tax and any related penalties or interest to the relevant tax authority.</li>
   <li>Tokens do not constitute property, and have no monetary value. Tokens are not currency, and do not constitute cash or cash equivalent. Tokens cannot be exchanged for cash or other goods or services. Tokens cannot be transferred to or exchanged with other users, and other than any Token designed for social sharing via third party platforms, cannot be used outside of the Sheba Music Service or Platform.</li>
   <li>Purchase and use of Tokens requires you to have an Sheba Music account. If you delete your account for any reason, if you permanently lose access to your account, or if your account is terminated pursuant to the Sheba Music Terms of Service, you will not be able to purchase Tokens and you will lose access to any Tokens you may already have purchased. We will not be liable for any refunds in respect of any unused Tokens in these cases.</li>
   <li>Without limiting any of our other rights, we may prevent you from purchasing or using Tokens if you breach any of these Terms, or breach any provision of the Sheba Music Terms of Service.</li>
   <li>These Terms govern your purchase and use of Tokens and are supplemental to the Sheba Music Terms of Service and other terms, conditions and policies governing your use of the Sheba Music Service or Platform. Your purchase of Tokens may also be subject to additional third party terms, for example relating to in-app purchases, and you are solely responsible for ensuring that you read, understand, and comply with applicable third party terms. In the event of any conflict between these Terms and the Sheba Music Terms of Service, these Terms will govern solely with respect to the Program, and solely to the extent of that conflict.</li>
   <li>If you have any questions about these Terms or the Program, you can contact us at <a className='text-red-500' href="mailto:support@shebamusicapp.com">support@shebamusicapp.com</a>.
   </li>
   </ul>
   </div>
   </div>
   </div>
   </div>
    
   
   </div>
     <Newsletter/> 
   

    
   </div>
  )
}

export default TermOfService
