import React , { useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css'; 
const Aboutsheba = () => {
   useEffect(()=>{
      AOS.init({duration:2000})
    },[]);
   const boxesShadow={
      boxShadow:' 6px 6px 12px 0px rgba(0, 0, 0, 0.4),  -5px -5px 10px 0px rgba(255, 255, 255, 0.1)',

   }
   
  return (
    <>
        <div className='container-fluid overflow-hidden'>
         <div className='container  mx-auto px-4' >
         <div className='grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2  lg:grid-cols-2  px-4 my-4'>
         <div className=' mt-4 lg:mt-24'>
             <h2 className='text-white  text-3xl sm:text-4xl xl:text-[40px] font-bold   leading-[45px] sm:leading-[56px] xl:leading-[58px]'> <span className='bg-gradient-to-r from-[#A30000]  via-[rgba(163, 0, 0, 1)]  to-transparent to-90%  rounded-2xl pl-2 py-2'>Sheba</span> Music App for Songs and Beats</h2>
             <p className='text-[#DADADA]  text-lg		 px-2 py-4 leading-6 '>Discover the Sheba Music app, your ultimate destination for songs and beats. Immerse yourself in a world of musical exploration and creativity.</p>




             <div className='flex px-0 sm:px-0 lg:px-0 py-2'>
            <div className='w-[10%] '>
            <div className='w-10 h-10 rounded-[10px] bg-[#A30000]  hover:bg-[#24272C] ' style={boxesShadow}>
            <img src='./Images/musictune.png' alt='img' className='mx-auto pt-[8px]'/>
            </div>
            </div>
            <div className='w-[90%]'>
            <div className='mx-4 sm:mx-2 md:mx-3 lg:mx-0 px-1'>
            <h2 className='font-bold	 text-xl			 text-white leading-9'>Listen to Millions of Songs</h2>
             <p className='text-[#DADADA] font-normal text-base	py-1		'>Experience endless music variety on Sheba Music platform.</p>
            </div>
            </div>
           
            </div>
            <div className='flex px-0 sm:px-0 lg:px-0 py-2'>
            <div className='w-[10%] '>
            <div className='w-10 h-10 rounded-[10px] bg-[#A30000]  hover:bg-[#24272C] ' style={boxesShadow}>
            <img src='./Images/musictune.png' alt='img' className='mx-auto pt-[8px]'/>
            </div>
            </div>
            <div className='w-[90%]'>
            <div className='mx-4 sm:mx-2 md:mx-3 lg:mx-0 px-1'>
            <h2 className='font-bold	 text-xl			 text-white leading-9'>Listen to Millions of Beats</h2>
             <p className='text-[#DADADA] font-normal text-base	py-1		'>Explore countless beats variety on Sheba Music.</p>
            </div>
            </div>
           
            </div>
            <div className='flex px-0 sm:px-0 lg:px-0 py-2'>
            <div className='w-[10%] '>
            <div className='w-10 h-10 rounded-[10px] bg-[#A30000]  hover:bg-[#24272C] ' style={boxesShadow}>
            <img src='./Images/discover.png' alt='img' className='mx-auto pt-[8px]'/>
            </div>
            </div>
            <div className='w-[90%]'>
            <div className='mx-4 sm:mx-2 md:mx-3 lg:mx-0 px-1'>
            <h2 className='font-bold	 text-xl			 text-white leading-9'>Discover New Tunes</h2>
             <p className='text-[#DADADA] font-normal text-base	py-1		'>Explore and discover new music from a diverse range of artists..</p>
            </div>
            </div>
           
            </div>
            <div className='flex px-0 sm:px-0 lg:px-0 py-2'>
            <div className='w-[10%] '>
            <div className='w-10 h-10 rounded-[10px] bg-[#A30000]  hover:bg-[#24272C] ' style={boxesShadow}>
            <img src='./Images/Vector.png' alt='img' className='mx-auto pt-[8px]'/>
            </div>
            </div>
            <div className='w-[90%]'>
            <div className='mx-4 sm:mx-2 md:mx-3 lg:mx-0 px-1'>
            <h2 className='font-bold	 text-xl			 text-white leading-9'>Create Playlist & Albums</h2>
             <p className='text-[#DADADA] font-normal text-base	py-1		'>Craft personalized playlists and albums on Sheba Music.</p>
            </div>
            </div>
           
            </div>

            <div className='flex px-0 sm:px-0 lg:px-0 py-2'>
            <div className='w-[10%] '>
            <div className='w-10 h-10 rounded-[10px] bg-[#A30000]  hover:bg-[#24272C] ' style={boxesShadow}>
            <img src='./Images/Download.png' alt='img' className='mx-auto pt-[8px]'/>
            </div>
            </div>
            <div className='w-[90%]'>
            <div className='mx-4 sm:mx-2 md:mx-3 lg:mx-0 px-1'>
            <h2 className='font-bold	 text-xl			 text-white leading-9'>Offline Playback</h2>
             <p className='text-[#DADADA] font-normal text-base	py-1		'>Play downloaded songs offline, ensuring uninterrupted music enjoyment even without an internet connection.</p>
            </div>
            </div>
           
            </div>


             

              

         </div>
         <div className='mt-0 md:mt-32 xl:mt-0'>
                     <img src='./Images/Group 1000002763.png' alt='img' className='w-full ' />
                </div>
         </div>
         </div>
        </div>
       
    </>
  )
}

export default Aboutsheba