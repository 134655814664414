import React from "react";
import { Route,  Routes,  } from "react-router-dom";
import HomePage from "./homepage";
import TermOfService from "pages/termOfService";
import PrivacyPolicy from "pages/privacyPolicy";
import TermsAndConditions from "pages/termAndCondition";
import DMCA from "pages/dmca";
const App = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/terms-of-service" element={<TermOfService />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-conditions" element={<TermsAndConditions />} />
        <Route path="/dmca" element={<DMCA />} />
        <Route path="*" element={<HomePage />} />
      </Routes>
    </div>
  );
};

export default App;
