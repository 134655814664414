import React, { useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';

const Video = () => {
  useEffect(()=>{
    AOS.init({duration:2000})
  },[]);
    const bideoback={
        backgroundImage: 'url("./Images/Rectanglevideo.jpg")',
     
        
    }

    
  return (
     <>
       <div className='container-fluid overflow-hidden '>
            <div className='container  mx-auto my-10 px-4'  >
            <div className='relative bideoback 	'  style={bideoback}>
            {/* <img src='./Images/Group 1000002614.png' alt='img' className='absolute top-6 right-4  xl:top-8 xl:right-16  '/> */}
 
                 <div className='responvideo'>
                 <div className=' mt-7 absolute inset-0 flex    justify-center'>
              <h2 className='font-bold text-2xl sm:text-4xl xl:text-[40px] text-white leading-[43px] sm:leading-[58px] mx-5 sm:mx-0 sm:text-center pt-28 sm:pt-32 '> <span className='bg-gradient-to-r from-[#291616]  via-[rgba(69,69,69,0.5)]   to-transparent   rounded-tl-2xl	rounded-bl-2xl	 pl-3 py-1'>How</span> to Upload Music and Sell  </h2>
              </div>
             <div className=' absolute  h-[180px] sm:h-[500px]  lg:h-[550px] xl:h-[650px] mt-[104px] sm:mt-28 md:mt-32 lg:mt-24 xl:mt-24  w-[95%] sm:w-[95%] md:w-[95%]   top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2  inset-0     '>
             <iframe  className='w-full h-full rounded-2xl sm:rounded-[50px]	' src="https://www.youtube.com/embed/Qxmzaex1mcE?si=_ukxrr71dWsGARul" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              </div>
                 </div>
             {/* <img src='Images/Group 1000002769.png'   alt='img' className='absolute bottom-[-41px] sm:bottom-[-50px] md:bottom-[-50px] lg:bottom-[-43px] xl:bottom-[-39px] left-6 sm:left-12'/>       */}
              </div>
             </div>      
            </div>
       
         {/* <img src='./Images/Group 1000002614.png' alt='img' className='absolute top-8 right-7 '/> */}
/
                {/* <div className=' pt-36	' >
                <h1 className='text-center font-bold	text-4xl text-white '><span  className='bg-gradient-to-r from-[#454545] from-30% via-[rgba(69, 69, 69, 0.5)] via-25% to-transparent to-90%  rounded-2xl px-4 py-2'>How</span>  to Upload Music on Sheba Music And Sell</h1> 
                </div> */}
                {/* <img src='./Images/Rectangle 187.png'alt='img' className='w-[95%] mx-auto my-4 h-[370px]'/>
                <img src='./Images/Group 1000002767.png' alt='img' className='absolute left-9 bottom-[-20px] '/> */}
     </>
  )
}

export default Video