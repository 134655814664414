import React, { useEffect, useState } from 'react'
import Navbar  from './Navbar'
import Banner from './banner'
import Join from './join'
import Features from './features'
import Aboutsheba from './aboutsheba'
import Video from './video'
import Ticketing from './ticketing'
import Getstarted from './getstarted'
// import Newlatter from './newlatter'
import Newsletter from './newsletter'
import ModalComponent from './modelComponent'
import { useLocation } from 'react-router-dom'
import { isAndroid, isIOS } from 'react-device-detect'
const HomePage = () => {
  const [isAndroidDevice, setIsAndroidDevice] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation(); 
  useEffect(() => {
    // Check if the device is Android
    if (location.pathname !== '/') {

      setIsAndroidDevice(true);// If yes, show the modal
      setIsModalOpen(true);
    } else {
      setIsModalOpen(false);// If not, don't show the modal
    }
  }, [location.pathname]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSwitchToApp = () => {
    
    const userAgent = navigator.userAgent;
    console.log(userAgent);
    console.log(isAndroid);

    if (isAndroid) {
      console.log('on Android browser')

      // Handle Android: Open the app or redirect to Play Store
      // const isAppInstalled = userAgent.includes('com.sheba.music');

      // if (isAppInstalled) {
        // App is installed, open the app

     
  
        const currentPath = window.location.pathname;
      const queryParams = new URLSearchParams(window.location.search).toString();
      const deepLink = `app://shebamusicapp.com/${currentPath}?${queryParams}`;
          // window.location.href = 'https://play.google.com/store/apps/details?id=com.sheba.music';

          // window.location.href = 'market://launch?id=com.sheba.music';

      // Open the app
      // window.location.href = deepLink;
      // Set a timeout to redirect to the Play Store if the app is not opened
      // const intentUrl = `intent://details?id=${appPackageName}#Intent;scheme=market;package=com.android.vending;end`;

      // window.open('market://launch?id=com.sheba.music', '_blank');

       // Create an invisible iframe to try opening the app using the custom URL scheme
       const iframe = document.createElement('iframe');
       iframe.style.display = 'none';
       iframe.src = deepLink;
       document.body.appendChild(iframe);

       // Fallback to Play Store URL if the app is not installed
       setTimeout(() => {
         document.body.removeChild(iframe);
         window.location.href = 'market://launch?id=com.sheba.music';
         ;
       }, 1000); 
      // setTimeout(() => {
      //   // Check if the browser has navigated to a new page
      //   if (document.hidden) {
      //     // The app was opened successfully
      //     console.log('App opened successfully');
      //   } else {
      //     // The app was not opened, redirect to the Play Store
      //     console.log('App not opened');
      //     // window.location.href = 'https://play.google.com/store/apps/details?id=com.sheba.music';
      //     window.location.href = 'market://launch?id=com.sheba.music';
      //     // window.open('market://launch?id=com.sheba.music', '_blank');
      //     // window.open('https://play.google.com/store/apps/details?id=com.sheba.music', '_blank');
      //     // market://details?id=<package_name>
      //   }
      // }, 500);
      // } 
      // else {
      //   // App is not installed, redirect to Play Store
      //   window.location.href = 'https://play.google.com/store/apps/details?id=com.sheba.music';
      // }
    } else if (isIOS) {
      console.log('on IOS browser')

      // Handle iOS: Open the app or redirect to App Store
      // window.location.href = 'your-ios-app-url';
      const currentPath = window.location.pathname;
      const queryParams = new URLSearchParams(window.location.search).toString();
      const deepLink = `app://shebamusicapp.com/${currentPath}?${queryParams}`;

      // Open the app
      window.location.href = deepLink;
      // Set a timeout to redirect to the Play Store if the app is not opened
      setTimeout(() => {
        // Check if the browser has navigated to a new page
        if (document.hidden) {
          // The app was opened successfully
          console.log('App opened successfully');
        } else {
          // The app was not opened, redirect to the Play Store
          console.log('App not opened');
          window.location.href = 'https://apps.apple.com/us/app/sheba-music-app/id6477535059';
        }
      }, 2000);
    }
    else{
      console.log('on desktop browser')
      const appPackageName = 'com.sheba.music'; // Replace with your app's package name

      // Construct the Google Play URL
      const googlePlayURL = `https://play.google.com/store/apps/details?id=${appPackageName}`;
  
      // Open the Google Play URL
      // window.location.href = googlePlayURL;
      window.open(googlePlayURL, '_blank');

    }
   
    // Handle the action when the user wants to switch to the app
    console.log('Switching to the app');
    // Optionally, you can add logic to open the app or redirect to the app store
    // For example, you can use window.location.href = 'your-app-scheme://'
  };
  return (
    <>  
    <Navbar/>
    <Banner/>
     <Join/>
     <Features/>
     <Aboutsheba/>
    <Video/>
     <Ticketing/>
     <Getstarted/> 
     <Newsletter/> 
     {isAndroidDevice && (
        <ModalComponent
          isOpen={isModalOpen}
          onRequestClose={handleCloseModal}
          onSwitchToApp={handleSwitchToApp}
        />
      )}
       
    
     {/* <Secsec/> */}
    {/* 
    <Aboutsheba/>
    <Video/>
    <Ticketing/>
    <Getstarted/>
    <Newlatter/>  */}

    
   </>
  )
}

export default HomePage
