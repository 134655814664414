import React, { useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css'; 
// import { isAndroid, isIOS } from 'react-device-detect';

const Banner = () => {
    useEffect(()=>{
      AOS.init({duration:2000})
    },[]);

  const boxesShadow={
    boxShadow:' 6px 6px 12px 0px rgba(0, 0, 0, 0.2),  -5px -5px 10px 0px rgba(255, 255, 255, 0.1)',
    
  }

  const openGooglePlay = () => {
       const appPackageName = 'com.sheba.music'; // Replace with your app's package name

      // Construct the Google Play URL
      const googlePlayURL = `https://play.google.com/store/apps/details?id=${appPackageName}`;
  
      // Open the Google Play URL
      window.location.href = googlePlayURL;
    
    // }
  }
  const openApple = () => {
   
      window.location.href = 'https://apps.apple.com/us/app/sheba-music-app/id6477535059';
    
    // }
  }

  return (
    <>

    <div className='container-fluid  bannershape  '>
    <div className='container  mx-auto relative ' >
       <div className='grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2  px-4 '>
           <div className='lg:mt-20 xl:mt-20   '>
             <div className='flex flex-wrap'>
             <div>
                <h1 className='font-bold  text-3xl sm:text-4xl xl:text-[40px] text-white leading-[45px] sm:leading-[58px]
             '><span className='bg-gradient-to-r from-[#291616]  via-[rgba(69,69,69,0.5)]   to-transparent   rounded-tl-2xl	rounded-bl-2xl	 pl-3 py-1'>Exp</span>erience  </h1>
             </div>
             <div className='mx-2'>
             <h1 className='font-bold text-3xl sm:text-4xl xl:text-[40px]  leading-[45px] sm:leading-[58px] text-[#292C30]' >Sheba Music!</h1>
             </div>
             </div>
             <p className='text-white  font-normal text-lg px-1	sm:px-3 leading-7	 pt-7 pb-3'>Welcome to Sheba Music, an innovative platform revolutionizing the music industry! For music fans, Sheba Music offers a unique streaming experience coupled with a loyalty rewards program. Discover new tunes, engage with your favorite artists, and earn rewards for your dedication.</p>
               <p className='text-white  font-normal text-lg px-1	sm:px-3 leading-7		pb-3'>To artists and producers, Sheba Music provides a direct-to-fan sales platform. Sell event tickets, albums, and songs directly to your audience without intermediaries. Additionally, showcase your talent by offering free songs and beats for fans to stream and enjoy.  </p>
               <p className='text-white  font-normal text-lg px-1 sm:px-3 leading-7	'>Download Sheba Music today and be part of a community that values creativity, direct engagement, and mutual appreciation between artists and their fans!</p>
               <div className='flex flex-row justify-center sm:justify-normal	my-5  sm:my-7  sm:mx-2'>
             <div className='flex rounded-3xl border-2	 hover:border-0	 px-1 py-1 w-[45%]  sm:w-[210px]  hover:bg-[#454545] transition-all duration-800 ease-in-out cursor-pointer mt-2 ' style={boxesShadow}>
              <div className=' flex justify-center items-center px-2 py-1 sm:px-3 sm:py-2 '>
                <img src='./Images/app-store 1.png' alt='img'/>
              </div>
              <div className='text-white px-1 py-2 sm:px-3 sm:py-2'  onClick={openApple} style={{ cursor: 'pointer' }}>
                <p className='text-[8px]  sm:font-normal sm:text-xs	'>Download on the</p>
                <h4 className='text-[12px] font-semibold	sm:font-normal sm:text-lg	'>App Store</h4>
              </div>
             </div>
             <div className='flex rounded-3xl  border-2 hover:border-0	px-1 py-1 sm:px-1 sm:py-1	 w-[45%] sm:w-[210px] hover:bg-[#454545] transition-all duration-800 ease-in-out  mx-2 sm:mx-3 md:mx-3 lg:mx-3	mt-2  cursor-pointer' style={boxesShadow}>
              <div className='flex justify-center items-center px-2 py-1  sm:px-3 sm:py-2  '>
                <img src='./Images/google-play 1.png' alt='img'/>
              </div>
              <div className='text-white px-1 py-2 sm:px-3 sm:py-2' onClick={openGooglePlay} style={{ cursor: 'pointer' }}>
                <p className='text-[8px]  sm:font-normal sm:text-xs	'>Get it on</p>
                <h4 className='text-[10px] font-semibold	 sm:font-normal sm:text-lg		'>Google Play</h4>
              </div>
             </div>

             </div>


             </div>
             <div className='mt-13 '>
             <div className='lg:h-auto xl:h-[100%] w-full  lg:mt-24 xl:mt-0'>
             <img src='./Images/Group 1000005941.png'  alt='img' className='w-full h-[100%]' />
             </div>
             <div>

             </div>
       
             </div>
       </div>
       <div className='absolute right-7 sm:right-20 sm:bottom-8   md:right-20 md:bottom-8 lg:right-6 lg:bottom-32	xl:right-12 xl:bottom-[40px]'/>
    </div>
    
    </div>
       
    </>
  )
}
export default Banner
