import React from 'react';
import Modal from 'react-modal';
import { isAndroid } from 'react-device-detect';

Modal.setAppElement('#root'); // Set the root element for screen reader accessibility

const ModalComponent = ({ isOpen, onRequestClose, onSwitchToApp }) => {
    const customStyles = {
        content: {
          position: 'fixed',
        //   top: '', 
          bottom: '0',
          left: '0',
          right: '0',
        //   height: '250px',
          maxHeight: '60%',
          inset: '0px',
          background: '#24272C',
          borderRadius: '16px 16px 0 0',
          padding: '20px',
          overflow: 'hidden',
          transition: 'transform 0.3s ease-out',
          transform:  'translateY(100%)' ,
        },
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 1000,
        },
      };
  return (
    <Modal
    style={customStyles}

      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Open in App Modal"
      
    >
    <button onClick={onRequestClose} className="absolute top-0 right-0 mt-4 mr-4 text-white text-lg font-semibold">&#10005; </button>
    
      <h2 className='text-white text-center text-xl font-bold mt-4'>It's better in the app</h2>
      <h4 className='text-white text-center text-xs pt-4'>Open the app for offline streaming curated playlists and personlized recommendations!</h4>
    

      <div className="flex flex-col space-y-8 mt-8">
      <button className='bg-[#A30000] text-white font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline' onClick={onSwitchToApp}>Switch to the App</button>
      {/* <button className='text-white font-bold py-2 px-4 rounded-full border border-white focus:outline-none focus:shadow-outline' onClick={onRequestClose}>Not Now</button> */}
      </div>
       </Modal>
  );
};

export default ModalComponent;