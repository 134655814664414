import React,{ useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css'; 

const Getstarted = () => {
  useEffect(()=>{
    AOS.init({duration:2000})
  },[]);
  const boxesShadow={
    boxShadow:' 6px 6px 12px 0px rgba(0, 0, 0, 0.2),  -5px -5px 10px 0px rgba(255, 255, 255, 0.1)',
    
  }
  const openGooglePlay = () => {
    const appPackageName = 'com.sheba.music'; // Replace with your app's package name

    // Construct the Google Play URL
    const googlePlayURL = `https://play.google.com/store/apps/details?id=${appPackageName}`;

    // Open the Google Play URL
    window.location.href = googlePlayURL;
  };
  const openApple = () => {
   
    window.location.href = 'https://apps.apple.com/us/app/sheba-music-app/id6477535059';
  
  // }
}
  return (
      <div className='container-fluid  overflow-hidden'>
        <div className='container  mx-auto ' >
                <div className=' h-[450px] sm:h-[300px] md:h-[250px] lg:h-[250px]  bg-[#A30000] mx-auto mt-10 sm:w-[100%] md:w-[100%] lg:w-[80%] rounded-3xl relative overflow-hidden'>
                <div className='flex flex-wrap pt-12 justify-center	'>
                   <div>
                     <h1 className='text-white font-bold text-4xl pt-2 sm:pt-0'>Get Started on</h1>
                   </div>
                   <div className='mx-2'>
                      <h1 className=' text-[#201919] font-bold text-4xl pt-2 sm:pt-0'>Sheba Music App!</h1>
                   </div>
                </div>
                {/* <div className='pt-12'  >
               <h1 className='text-center text-white font-bold text-4xl pt-2 sm:pt-0'>  Get Started for <span className='text-[#201919] font-bold text-3xl'>Sheba Music App!</span> </h1>
                </div> */}
                <div className='pt-5 px-1 sm:px-32 md:px-24 lg:px-40'>
                 <p className='text-center text-[#CBCBCB] text-sm	'> Sheba Music, an innovative platform revolutionizing the music industry! Download the app and enjoy songs and beats now.</p>
                </div>
                <div className='flex flex-col sm:flex-row justify-center  my-4 '>
             <div className='flex  rounded-2xl border	 px-2 hover:border-0 hover:bg-[#454545] cursor-pointer mx-6 sm:mx-0 transition-all duration-800 ease-in-out ' style={boxesShadow}>
              <div className='px-3 py-2'>
                <img src='./Images/app-store 1.png' alt='img'/>
              </div>
              <div className='text-white px-3 py-2' onClick={openApple} style={{ cursor: 'pointer' }}>
                <p className='text-xs	'>Download on the</p>
                <h4 className='text-xl font-medium			'>App Store</h4>
              </div>
             </div>
             <div className='flex  rounded-2xl mx-6 sm:mx-0 sm:ml-2 px-2 border hover:border-0 hover:bg-[#454545] cursor-pointer mt-2 sm:mt-0  transition-all duration-800 ease-in-out  	'  style={boxesShadow}>
              <div className='px-3 py-2'>
                <img src='./Images/google-play 1.png' alt='img'/>
              </div>
              <div className='text-white px-3 py-2' onClick={openGooglePlay} style={{ cursor: 'pointer' }}>
                <p className='text-xs	'>Get it on</p>
                <h4 className='text-xl 	font-medium		'>Google Play</h4>
              </div>
             </div>

             </div>
                    {/* <img src='./Images/Ellipse 34.png' alt='img' className='absolute top-8	'/>
                    <img src='./Images/dotsgroup.png' alt='img' className='absolute sm:top-24 sm:left-12 md:top-14 md:left-10 lg:left-14 	'/>
                    <img src='./Images/linescurves.png' alt='img' className='absolute sm:left-8 md:left-14	bottom-16 curves'/> */}
                    {/* <img src='./Images/twoplus.png' alt='img' className='absolute  right-14  sm:top-2	md:top-10'/> */}
                    {/* <img src='./Images/Ellipse 33.png' alt='img' className='absolute  right-0 top-0	sm:top-20'/> */}
                </div>
        </div>
      </div>
  )
}

export default Getstarted