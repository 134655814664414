import React, { useEffect, useState } from 'react'
import Navbar  from './../Navbar'

// import Newlatter from './newlatter'
import Newsletter from './../newsletter'
import { useLocation } from 'react-router-dom'
import { isAndroid, isIOS } from 'react-device-detect'
const  PrivacyPolicy = () => {
 
  return (
    <div >  
    <Navbar/>
   <div  className=" px-8 md:px-12 _Text_1x6i2_1">
 
    <div className=" pr-10 pt-50 pt-xs-30 pt-md-30 pb-15">
   <div className="about_headerSection__bgNR5">
   <h1 className="py-10 sm:py-28 text-xl sm:text-4xl font-bold text-center text-white">
   Sheba Music Privacy Policy

   </h1>
   </div>
   <div className="" role="region">
   <div className="about_pageSection__vYP_F">
    
   {/* <h2 className='_Text_Heading'>
   User Acceptance
   </h2> */}

   <p className="_Text_1x6i2_1">
   Sheba Music ("us," "we," or "our") operates the Sheba Music app (the "App"). This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our App and the choices you have associated with that data.
</p>
   <p className="_Text_1x6i2_1">
   We use your data to provide and improve the App. By using the App, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.</p>
   <h2 className='_Text_Heading'>Definitions</h2>
   <p className="_Text_1x6i2_1"><strong> Personal Data: </strong>  Personal data means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).</p>
   <p className="_Text_1x6i2_1"><strong> Usage Data: </strong>  Usage data is data collected automatically either generated by the use of the App or from the App infrastructure itself (for example, the duration of a page visit).</p>
   <p className="_Text_1x6i2_1"><strong> Cookies: </strong>  Cookies are small files stored on your device (computer or mobile device).</p>
   <p className="_Text_1x6i2_1"><strong> Data Controller: </strong>  Data controller means a person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any personal data are, or are to be, processed. For the purpose of this Privacy Policy, we are a Data Controller of your data.</p>
   <p className="_Text_1x6i2_1"><strong> Data Processors (or Service Providers): </strong>  Data processor (or service provider) means any person (other than an employee of the Data Controller) who processes the data on behalf of the Data Controller. We may use the services of various Service Providers in order to process your data more effectively.</p>
   <p className="_Text_1x6i2_1"><strong> Data Subject (or User): </strong>  Data subject is any living individual who is using our App and is the subject of personal data.</p>
   
   <h2 className='_Text_Heading'>Collection and Use of Personal Data</h2>
   <p className="_Text_1x6i2_1">While using our App, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:</p>
   <ul style={{ listStyleType: 'disc' }} className='px-7'>
   <li>Email address</li>
   <li>First name and last name</li>
   <li>Phone number</li>
   <li>Address, State, Province, ZIP/Postal code, City</li>
   <li>Cookies and Usage Data</li>
   </ul>
  
   <p className="_Text_1x6i2_1">We may use your Personal Data to contact you with newsletters, marketing or promotional materials, and other information that may be of interest to you. You may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or instructions provided in any email we send.</p>

   <h2 className='_Text_Heading'>Usage Data</h2>
   <p className="_Text_1x6i2_1">We may also collect information on how the App is accessed and used ("Usage Data"). This Usage Data may include information such as your device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our App that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
   
   <h2 className='_Text_Heading'>Cookies</h2>
   <p className="_Text_1x6i2_1">Cookies are sent to your browser from a website and stored on your device. We use cookies to collect information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our App</p>
   <h2 className='_Text_Heading'>Use of Data</h2>
   <p className="_Text_1x6i2_1">Sheba Music uses the collected data for various purposes:</p>
   <ul style={{ listStyleType: 'disc' }} className='px-7'>
   <li>To provide and maintain our App</li>
   <li>To notify you about changes to our App</li>
   <li>To allow you to participate in interactive features of our App when you choose to do so</li>
   <li>To provide customer care and support</li>
   <li>To provide analysis or valuable information so that we can improve the App</li>
   <li>To monitor the usage of the App</li>
   <li>To detect, prevent and address technical issues</li>
   </ul>

   <h2 className='_Text_Heading'>Legal Basis for Processing Personal Data under the General Data Protection Regulation (GDPR)</h2>
   <p className="_Text_1x6i2_1">If you are from the European Economic Area (EEA), Sheba Music legal basis for collecting and using the personal information described in this Privacy Policy depends on the Personal Data we collect and the specific context in which we collect it.</p>
   <p className="_Text_1x6i2_1">Sheba Music may process your Personal Data because:</p>
   <ul style={{ listStyleType: 'disc' }} className='px-7'>
   <li>We need to perform a contract with you</li>
   <li>You have given us permission to do so</li>
   <li>The processing is in our legitimate interests and it's not overridden by your rights</li>
   <li>For payment processing purposes</li>
   <li>To comply with the law</li>
   </ul>

   <h2 className='_Text_Heading'>Retention of Data</h2>
   <p className="_Text_1x6i2_1">Sheba Music will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
   <p className="_Text_1x6i2_1">Sheba Music will also retain Usage Data for internal analysis purposes.</p>



   </div>
   </div>
   </div>
    
   
   </div>
     <Newsletter/> 
   

    
   </div>
  )
}

export default PrivacyPolicy
