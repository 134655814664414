import React, { useEffect, useState } from 'react'
import Navbar  from './../Navbar'

// import Newlatter from './newlatter'
import Newsletter from './../newsletter'
import { useLocation } from 'react-router-dom'
import { isAndroid, isIOS } from 'react-device-detect'
const  TermsAndConditions = () => {
 
  return (
    <div >  
    <Navbar/>
   <div  className=" px-8 md:px-12 _Text_1x6i2_1">
 
    <div className=" pr-10 pt-50 pt-xs-30 pt-md-30 pb-15">
   <div className="about_headerSection__bgNR5">
   <h1 className="py-10 sm:py-28 text-xl sm:text-4xl font-bold text-center text-white">
   Sheba Music Terms and Conditions

   </h1>
   </div>
   <div className="" role="region">
   <div className="about_pageSection__vYP_F">
    
   {/* <h2 className='_Text_Heading'>
   User Acceptance
   </h2> */}

   <p className="_Text_1x6i2_1">
   By downloading, installing, or using the Sheba Music app (hereinafter referred to as "the App"), you agree to be bound by these Terms and Conditions. If you do not agree to these Terms and Conditions, you may not use the App.
</p>
    <h2 className='_Text_Heading'>License</h2>
   <p className="_Text_1x6i2_1"><strong> License: </strong>  The App is licensed, not sold, to you by Sheba Music. Sheba Music reserves all rights not expressly granted to you.</p>
   <h2 className='_Text_Heading'>Use of the App</h2>

   <p className="_Text_1x6i2_1"><strong> Use of the App: </strong>  You may use the App solely for your personal, non-commercial purposes. You may not copy, modify, distribute, sell, or lease any part of the App, nor may you reverse engineer or attempt to extract the source code of the App, unless permitted by law.</p>

   <h2 className='_Text_Heading'>User Content and User Conduct</h2>
   <ul style={{ listStyleType: 'disc' }} className='px-7'>
   <li>You may have the ability to upload, post, or otherwise transmit content, including but not limited to music, beats, videos, messages, event tickets, and links ("User Content") using the App.</li>
   <li>You are solely responsible for your User Content and agree that Sheba Music is only acting as a passive conduit for your online distribution and publication of your User Content.</li>
   <li>You represent and warrant that you own or have the necessary rights and permissions to use your User Content.</li>
   <li>You may not use the App to engage in any illegal, harmful, or offensive activities, including but not limited to, the transmission of spam, harassment, threats, or offensive content.</li>
   <li>You may not use the App to infringe upon the intellectual property rights of others.</li>
   </ul>
  

   <h2 className='_Text_Heading'>Paid Features and Services</h2>
   <p className="_Text_1x6i2_1">The App may offer certain paid features and services, such as the ability to purchase beats, songs, event tickets, or access to paid live streams.</p>
   <ul style={{ listStyleType: 'disc' }} className='px-7'>
    <li>You agree to pay the fees for any such paid features or services.</li>
    <li>All fees are non-refundable, unless otherwise specified.</li>
   </ul>
   <h2 className='_Text_Heading'>Third-Party Content and Links</h2>
   <p className="_Text_1x6i2_1">The App may include content, products, or services from third parties or links to third-party websites or resources. You acknowledge and agree that Sheba Music is not responsible or liable for any such third-party content or resources.</p>
   <h2 className='_Text_Heading'>Termination</h2>
   <p className="_Text_1x6i2_1">Sheba Music may suspend or terminate your access to the App at any time and for any reason, with or without notice.</p>
   <h2 className='_Text_Heading'>Disclaimer of Warranties</h2>
   <p className="_Text_1x6i2_1">The App is provided on an "as is" and "as available" basis. Sheba Music disclaims all warranties of any kind, whether express or implied, including but not limited to, the implied warranties of merchantability, fitness for a particular purpose, and non-infringement.</p>
   <h2 className='_Text_Heading'>Limitation of Liability</h2>
   <p className="_Text_1x6i2_1">In no event shall Sheba Music be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with the use of the App, including but not limited to, damages for loss of profits, goodwill, use, data, or other intangible losses.</p>
   <h2 className='_Text_Heading'>Changes to the Terms and Conditions</h2>
   <p className="_Text_1x6i2_1">Sheba Music reserves the right to modify these Terms and Conditions at any time. Your continued use of the App following the posting of any changes will constitute your acceptance of these changes.</p>


   </div>
   </div>
   </div>
    
   
   </div>
     <Newsletter/> 
   

    
   </div>
  )
}

export default TermsAndConditions
