import React, { useEffect, useState } from 'react'
import Navbar  from './../Navbar'

// import Newlatter from './newlatter'
import Newsletter from './../newsletter'
import { useLocation } from 'react-router-dom'
import { isAndroid, isIOS } from 'react-device-detect'
const  DMCA = () => {
 
  return (
    <div >  
    <Navbar/>
   <div  className=" px-8 md:px-12 _Text_1x6i2_1">
 
    <div className=" pr-10 pt-50 pt-xs-30 pt-md-30 pb-15">
   <div className="about_headerSection__bgNR5">
   <h1 className="py-10 sm:py-28 text-xl sm:text-4xl font-bold text-center text-white">
   Sheba Music Digital Millennium Copyright Act (DMCA) Policy


   </h1>
   </div>
   <div className="" role="region">
   <div className="about_pageSection__vYP_F">
    
   {/* <h2 className='_Text_Heading'>
   User Acceptance
   </h2> */}

   <p className="_Text_1x6i2_1">
   Sheba Music ("the App") respects the intellectual property rights of others and expects its users to do the same. In accordance with the Digital Millennium Copyright Act (DMCA), the App has adopted the following policy to address claims of copyright infringement.
</p>
    <h2 className='_Text_Heading'>Notification of Alleged Copyright Infringement</h2>
   <p className="_Text_1x6i2_1">If you believe that your work has been copied in a way that constitutes copyright infringement, please provide the App's copyright agent with a written notification that includes the following information:</p>
   <ul style={{ listStyleType: 'disc' }} className='px-7'>
   <li><strong>a).</strong>  A physical or electronic signature of the copyright owner or the person authorized to act on their behalf.</li>
   <li><strong>b).</strong>  A description of the copyrighted work that you claim has been infringed.</li>
   <li><strong>c).</strong>  A description of the material that you claim is infringing and where it is located on the App.</li>
   <li><strong>d).</strong>  Your address, telephone number, and email address.</li>
   <li><strong>e).</strong>  A statement by you that you have a good faith belief that the use of the material is not authorized by the copyright owner, its agent, or the law.</li>
   <li><strong>f).</strong>  A statement that the information in the notification is accurate and, under penalty of perjury, that you are the copyright owner or are authorized to act on their behalf.</li>

    </ul>
   
   <p className="_Text_1x6i2_1"> Upon receiving a proper notification of alleged copyright infringement, the App will remove or disable access to the material identified in the notification, forward the notification to the alleged infringer, and take reasonable efforts to notify the alleged infringer that the App has removed or disabled access to the material.</p>

   <h2 className='_Text_Heading'>Counter-Notification:</h2>
   <p className="_Text_1x6i2_1">  If you believe that your material has been removed or disabled by mistake or misidentification, you may send a written counter-notification to the App's copyright agent. Your counter-notification must include the following information:</p>

   <ul style={{ listStyleType: 'disc' }} className='px-7'>
   <li>A physical or electronic signature.</li>
   <li>A description of the material that has been removed or to which access has been disabled and the location at which the material appeared before it was removed or access to it was disabled.</li>
   <li>A statement under penalty of perjury that you have a good faith belief that the material was removed or disabled as a result of mistake or misidentification of the material to be removed or disabled.</li>
   <li>Your name, address, and telephone number, and a statement that you consent to the jurisdiction of the federal district court for the judicial district in which your address is located, or if your address is outside of the U.S.A., for any judicial district in which the App may be found, and that you will accept service of process from the person who provided the original notification or an agent of that person..</li>
   </ul>
  

   <p className="_Text_1x6i2_1"> Upon receiving a proper counter-notification, the App will promptly provide the person who provided the original notification with a copy of the counter-notification and inform that person that the App will replace the removed material or cease disabling access to it in 10 business days. The App will replace the removed material and cease disabling access to it, unless the App's copyright agent first receives notice from the person who provided the original notification that they have filed an action seeking a court order to restrain the alleged infringer from engaging in infringing activity relating to the material on the App.
   </p>
   <p className="_Text_1x6i2_1"> Please note that this DMCA Policy is subject to change at any time.</p>
   <p className="_Text_1x6i2_1"> If you have any questions about this policy, please contact the App's copyright agent:</p>
   <p className="_Text_1x6i2_1"> <strong>Name: </strong> Sheba Music LLC</p>
   <p className="_Text_1x6i2_1"> <strong>Address: </strong>  5439 100th Street, Corona ,New York, 11368, USA</p>
   <p className="_Text_1x6i2_1"> <strong>Email: </strong>  <a href='mailto:info@shebamusicapp.com' className='text-red'>info@shebamusicapp.com</a></p>
  
   </div>
   </div>
   </div>
    
   
   </div>
     <Newsletter/> 
   

    
   </div>
  )
}

export default DMCA
