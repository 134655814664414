import React,{ useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css'; 
const Join = () => {
  useEffect(()=>{
    AOS.init({duration:2000})
  },[]);
  
    const boxesShadow={
      boxShadow:' 6px 6px 12px 0px rgba(0, 0, 0, 0.4),  -5px -5px 10px 0px rgba(255, 255, 255, 0.1)',
      
    }
    const openGooglePlay = () => {
      const appPackageName = 'com.sheba.music'; // Replace with your app's package name
  
      // Construct the Google Play URL
      const googlePlayURL = `https://play.google.com/store/apps/details?id=${appPackageName}`;
  
      // Open the Google Play URL
      window.location.href = googlePlayURL;
    };
  return (
    <>
       <div className='container-fluid overflow-hidden  '>
      
       <div className='container mx-auto mb-24 relative'  >
        <div className='grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2  mt-40   sm:mt-56 px-4	'>
            <div className='order-2 lg:order-1'>
                <img src='Images/Group 1000002618.png' alt='img' className='mx-0 sm:mx-auto xl:mx-0'/>
            </div>
            <div className=' px-0  sm:px-6  order-1 lg:order-2'>
            <div className='px-0 sm:px-0 lg:px-4 py-3 mt-1 lg:mt-1 xl:mt-24	' >
            <div className='flex flex-wrap'>
             <div>
                <h1 className='font-bold  text-3xl sm:text-4xl xl:text-[40px] text-white leading-[45px] sm:leading-[58px]
             '><span className='bg-gradient-to-r from-[#a30000] from-30% via-[rgba(163, 0, 0, 0.5)] via-5% to-transparent to-90% rounded-2xl pl-3 py-2'>You</span> can Join   </h1>
             </div>
             <div className='mx-2'>
             <h1 className='font-bold text-3xl sm:text-4xl xl:text-[40px]  leading-[45px] sm:leading-[58px] text-[#A30000]' >Live Streaming! </h1>
             </div>
             </div>
            {/* <h2 className='text-white text-3xl sm:text-4xl xl:text-[40px] font-bold   leading-[45px] sm:leading-[56px] xl:leading-[58px]'> <span className='bg-gradient-to-r from-[#a30000] from-30% via-[rgba(163, 0, 0, 0.5)] via-5% to-transparent to-90% rounded-2xl pl-3 py-2'>You</span> can Join <b className='text-[#A30000]'> Live Streaming! </b></h2>  */}
            </div>
             <div className='px-0  sm:px-0 lg:px-6 my-3'>
               <p className='text-white text-lg	leading-7	 pb-6'> Experience Sheba Music's vibrant world of live performances, interviews, and exclusive content. Tune in for an immersive journey into musical creativity.</p>
             </div>
    

            <div className='flex px-0 sm:px-0 lg:px-6 py-2'>
            <div className='w-[10%] '>
            <div className='w-10 h-10 rounded-[10px] bg-[#A30000]  hover:bg-[#24272C] ' style={boxesShadow}>
            <img src='./Images/radio.png' alt='img' className='mx-auto pt-[8px]'/>
            </div>
            </div>
            <div className='w-[90%]'>
            <div className='ml-2 px-2'>
            <h2 className='font-bold	 text-xl			 text-white leading-9'>Innovative Streaming Experience</h2>
             <p className='text-[#DADADA] font-normal text-base	py-1		'>Enjoy a unique and innovative streaming platform.</p>
            </div>
            </div>
           
            </div>

            <div className='flex px-0 sm:px-0 lg:px-6 py-2'>
            <div className='w-[10%] '>
            <div className='w-10 h-10 rounded-[10px] bg-[#A30000]  hover:bg-[#24272C] ' style={boxesShadow}>
            <img src='Images/videolive.png' alt='img' className='mx-auto pt-[11px]'/>
            </div>
            </div>
            <div className='w-[90%] '>
            <div className='ml-2 px-2'>
            <h2 className='font-bold	 text-xl			 text-white leading-9'>Live Stream Events</h2>
             <p className='text-[#DADADA] font-normal text-base	py-1		'>Tune in to live performances, interviews, and exclusive content on Sheba Music.</p>
            </div>
            </div>
           
            </div>
           
              <button className='w-[221px] h-[45px] text-center font-bold bg-[#A30000] text-white rounded-[10px] ml-0 md:ml-4 my-3 hover:bg-[#24272C] transition-all duration-800 ease-in-out' onClick={openGooglePlay} style={{...boxesShadow, cursor: 'pointer' }}> Join Now</button>
            </div>
        </div>

          {/* <img src='Images/Ellipse 35.png'   alt='img' className='absolute right-0 sm:right-0 md:right-3 lg:right-10	xl:right-0'/> */}
       </div>
       </div>
       
    </>
  )
}

export default Join
