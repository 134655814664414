import React,{ useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css'; 

const Ticketing = () => {
   useEffect(()=>{
      AOS.init({duration:2000})
    },[]);
   const boxesShadow={
      boxShadow:' 6px 6px 12px 0px rgba(0, 0, 0, 0.4),  -5px -5px 10px 0px rgba(255, 255, 255, 0.1)',
      
    }
  return (
    <>
        <div className='container-fluid  my-10 sm:my-5 overflow-hidden'>
         <div className='container relative  mx-auto ' >
         {/* <img src='./Images/Group 1000002769.png'  alt='img' className='absolute right-10 top-7'/> */}
         <div className='grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2  lg:grid-cols-2  px-4'>
         <div className='	my-0 md:my-24 order-2 md:order-1  '>
             <img src='./Images/Group 1000002768.png' alt='img' className=' mx-auto lg:mx-0 mt-0 md:mt-12 lg:mt-0' />
         </div>
         <div className='order-1 md:order-2	'>
            <div className='sm:mt-10  md:mt-36 lg:mt-48 mx-4   ' > 
            <h1 className='font-bold  text-3xl sm:text-4xl xl:text-[40px]   leading-[45px] sm:leading-[56px] xl:leading-[58px] pt-24 md:pt-0	 text-white 
             '><span className='bg-gradient-to-r from-[#A30000]  via-[rgba(163, 0, 0, 1)]  to-transparent to-90%  rounded-2xl pl-2 py-2'>Inte</span>grated <span className='md:pl-1 lg:pl-0'>Ticketing</span> <span className=' md:pl-0 lg:pl-2 '>Platform</span>  </h1> </div>
             <div className='text-lg font-normal	text-[#E1E1E1] px-5 py-3'>
            Seamlessly purchase event tickets through Sheba Music for live shows and experiences.
            </div>
           
            <div className='mx-4 mt-5'>
            <div className='flex px-0 sm:px-0 lg:px-6 py-2'>
            <div className='w-[10%] '>
            <div className='w-9 h-9 rounded-[10px] bg-[#24272C]  hover:bg-[#A30000] ' style={boxesShadow}>
            <img src='./Images/Ticketcom.png' alt='img' className='mx-auto pt-[4px]'/>
            </div>
            </div>
            <div className='w-[90%]'>
            <div className='ml-2 px-2'>
            <h2 className='font-bold	 text-xl			 text-white leading-9'>Tickets based on Recommendation</h2>
             <p className='text-[#DADADA] font-normal text-base'>Get recommended tickets based on Sheba Music's Ai Algorithm.</p>
            </div>
            </div>
            </div>
            <div className='flex px-0 sm:px-0 lg:px-6 py-2'>
            <div className='w-[10%] '>
            <div className='w-9 h-9 rounded-[10px] bg-[#24272C]  hover:bg-[#A30000] ' style={boxesShadow}>
            <img src='Images/Ticketcom.png' alt='img' className='mx-auto pt-[4px]'/>
            </div>
            </div>
            <div className='w-[90%] '>
            <div className='ml-2 px-2'>
            <h2 className='font-bold	 text-xl			 text-white leading-9'>Live Stream Events</h2>
             <p className='text-[#DADADA] font-normal text-base			'>Tune in to live performances, interviews, and exclusive content on Sheba Music.</p>
            </div>
            </div>
           
            </div>
                
             </div>

         </div>

         </div>

         </div>
        </div>
    </>
   )
}

export default Ticketing